/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';



/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/assets/app.css";
@import "~@swimlane/ngx-datatable/themes/dark.css";
@import "~@swimlane/ngx-datatable/themes/bootstrap.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

// --ion-color-primary: #c4091d;

:root {
  --ion-color-primary: #115848;
  --ion-color-primary-rgb: 33,33, 33;
  --ion-color-primary-contrast: #f4f4f4;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #115848;
  --ion-color-primary-tint: #115848;

  --ion-color-secondary: #c4091d;
  --ion-color-secondary-rgb: 33,33, 33;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #6b0303;
  --ion-color-secondary-tint: #6b0303;

  --ion-color-tertiary: #c4091d;
  --ion-color-tertiary-rgb: 33,33, 33;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #6b0303;
  --ion-color-tertiary-tint: #6b0303;

  --ion-color-toastok: #bbf7bb;;
  --ion-color-toastok-rgb: 187,247,187;
  --ion-color-toastok-contrast: #ffffff;
  --ion-color-toastok-contrast-rgb: 255,255,255;
  --ion-color-toastok-shade: #000080;
  --ion-color-toastok-tint: #0f0f82;

  --ion-color-toastwarn: #f3f7bb;
  --ion-color-toastwarn-rgb: 243,247,187;
  --ion-color-toastwarn-contrast: #ffffff;
  --ion-color-toastwarn-contrast-rgb: 255,255,255;
  --ion-color-toastwarn-shade: #000080;
  --ion-color-toastwarn-tint: #0f0f82;

  --ion-color-toasterr: #fcc0c0;
  --ion-color-toasterr-rgb: 236, 192, 192;
  --ion-color-toasterr-contrast: #ffffff;
  --ion-color-toasterr-contrast-rgb: 255,255,255;
  --ion-color-toasterr-shade: #000080;
  --ion-color-toasterr-tint: #0f0f82;

  --ion-color-toastok: #bbf7bb;;
  --ion-color-toastok-rgb: 187,247,187;
  --ion-color-toastok-contrast: #ffffff;
  --ion-color-toastok-contrast-rgb: 255,255,255;
  --ion-color-toastok-shade: #000080;
  --ion-color-toastok-tint: #0f0f82;

  --ion-color-toastwarn: #f3f7bb;
  --ion-color-toastwarn-rgb: 243,247,187;
  --ion-color-toastwarn-contrast: #ffffff;
  --ion-color-toastwarn-contrast-rgb: 255,255,255;
  --ion-color-toastwarn-shade: #000080;
  --ion-color-toastwarn-tint: #0f0f82;

  --ion-color-toasterr: #fcc0c0;
  --ion-color-toasterr-rgb: 236, 192, 192;
  --ion-color-toasterr-contrast: #ffffff;
  --ion-color-toasterr-contrast-rgb: 255,255,255;
  --ion-color-toasterr-shade: #000080;
  --ion-color-toasterr-tint: #0f0f82;

}

.landing {
  position: relative;
}

.landing-bg {
  // --background: linear-gradient(-170deg, #115848 64%, #ffffff 30%); // modifiche background per cre-ama
  // background: linear-gradient(-170deg, #115848 64%, #ffffff 30%); // modifiche background per cre-ama
  // padding-bottom: 10px; // modifiche background per cre-ama
  // min-height: 100%; // modifiche background per cre-ama
  // background-image: url("./assets/condomini_sfondo.jpg"); // modifiche background per cre-ama
  // background-repeat: no-repeat; // modifiche background per cre-ama
  // background-size: 100%; // modifiche background per cre-ama
  padding-bottom: 100%; // modifiche background per cre-ama
}

// ion-content {
//   // --background: #222428;
//   // background-image: url("./assets/condomini_sfondo.jpg");
// }
ion-content{
  --background: #fff url('/assets/condomini_sfondo.jpg') no-repeat center center / cover;
}

ion-card-content {
  height: 100%;
  width: 100%;
  margin: 0%;
  padding: 0%;
}

.searchTable {
  margin: auto;
  margin-bottom: 10px;
  width: 60%;
  border-bottom: 4px solid #FFC107;
  border-radius: 5px;
  background: white;
  --padding-start: 12px;
}

.footer {
  background: black;
  padding: 0px;
  bottom: 0px;
  position: fixed;
  width: 100%;
}

.titolo-pagina {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: #960610;
  padding: 5px 10px 10px 10px;
  font-weight: bold;
}

.titolo-selezioni {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: #980913;
  padding: 5px 10px 10px 10px;
  font-weight: normal;
}

.titolo-pagina {
margin-left: 10px;
margin-right: 10px;
margin-top: 10px;
background-color: #e51c28;
padding: 5px 10px 10px 10px;
font-weight: bold;
}

.titolo-selezioni {
margin-left: 10px;
margin-right: 10px;
margin-top: 10px;
background-color: #e51c28;
padding: 5px 10px 10px 10px;
font-weight: normal;
}

.immagine-avatar-profilo  {
width:100% !important;
height : 100% !important;
max-width: 250px !important;
max-height: 250px !important;
}


.zeromargin {
margin: 0px 0px 0px 0px;

}

.zeropadding {
padding: 0px 0px 0px 0px;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}



ion-title{
  @media screen and (max-width: 600px)
  {
    padding-inline: unset;
  }
}

.page-count{
  @media screen and (max-width: 600px)
  {
    position: absolute;
    z-index: -10000;
    color: transparent;
  }
}

.datatable-scroll {
  width: 100% !important;
}

.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group
{
   background-color: white;
}

ion-col.periodo ion-select{
  @media screen and (max-width: 600px)
  {
    margin-top: 5px;
  }
  background: #ffffff61;
  color: black;
  font-weight: 600;
  font-size: unset;
  border-radius: 5px;
}

.totFat {
  text-align: right !important;
}

.contrattoRow {
  font-size: 10px !important;
  line-height: 0px !important;
  vertical-align: top !important;
  white-space: break-spaces !important;
  padding-top: 10px !important;
}

.tipoNolPers {
  background: #FEFFAB !important;
}

.sospeso {
  background: #FAEFB4 !important;
}

.tipoPropCli {
  background: #94FFC9 !important;
}

.tipoAltro {
  background: #FFC3FF !important;
}


.stimato {
  background: #80808054 !important;
}

.totale {
  background: #47a4da54 !important;
}

.totale:hover{
  background: #47a4da54 !important;
}

div.datatable-row-detail {
  height: 100% !important;
}

.custom-toast {
  font-weight: 400;
  text-align: center;
}

.modalFineOrdine {
  --height: 800px;
  align-items: center;
  justify-content: center;
  &::part(content){
    width: 80%;
    --background: transparent;
    --box-shadow: 0px;
  }
}

.popover-wide {
  --width: min-content;

}
.msgError{
  .alert-wrapper {
    .alert-head {
        h2 {
          background-color: white;
          color:red;
        }
    }
    .alert-message {
      color: black;
      font-weight: 400;
    }
  }
}

:root {
  .popover-content {
      left: 0 !important;
      width: 100%;
  }
}

.datatable-header-cell {
  font-size: medium!important;
  background-color:#888989 !important;
  color:white!important;
}

.page-count{
  font-size: medium!important;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

@media (max-width: 450px) and (min-width: 0px)  {
  ion-grid {
    font-size: 60% !important;
  }
}

@media (max-width: 768px) and (min-width: 451px)  {
  ion-grid {
    font-size: 90% !important;
  }
}

ion-col {
  color: #101010;
  font-weight: 500;
}

// ion-card {
//   background-color: transparent;
// }

.selAgente {
  width: 290px;
  margin: auto;
  background: white;
  margin-bottom: 10px;
  border-bottom: 4px solid #FFC107;
  border-radius: 5px;
}

/* ----------- 0 - 450px ----------- */
@media screen
  and (max-width: 450px){
    * {
      font-size: 90%;
    }
}

/* ----------- 450 - 650px ----------- */
@media screen
  and (min-width: 451px)
  and (max-width: 650px){
    * {
      font-size: 92%;
    }
}

/* ----------- 650px - 950px ----------- */
@media screen
  and (min-width: 651px)
  and (max-width: 950px){
    * {
      font-size: 94%;
    }
}

/* ----------- 950px - 1200px ----------- */
@media screen
  and (min-width: 951px)
  and (max-width: 1200px){
    * {
      font-size: 96%;
    }
}

/* ----------- 1200px + ----------- */
// @media screen
//   and (min-width: 1201px){
//     * {
//       font-size: 100%;
//     }
// }

.leggibile ion-row:nth-child(even) {
  background-color: #f2f2f2;
  //border: 1px solid #cccccc;

}

.leggibile ion-row:nth-child(odd) {
  background-color: #ffffff;
  //border: 1px solid #cccccc;

}

.leggibile-medium ion-row:nth-child(even) {
  background-color: #e5e5e5;
  //border: 1px solid #cccccc;

}

.leggibile-medium ion-row:nth-child(odd) {
  background-color: #ffffff;
  //border: 1px solid #cccccc;

}

.leggibile-dark ion-row:nth-child(even) {
  background-color: #d0d0d0;
  //border: 1px solid #cccccc;

}

.leggibile-dark ion-row:nth-child(odd) {
  background-color: #ffffff;
  //border: 1px solid #cccccc;

}
.leggibile-none ion-row:nth-child(even) {
  background-color: #ffffff00;
  border-bottom: 1px solid #A0A0A0;

}

.leggibile-none ion-row:nth-child(odd) {
  background-color: #ffffff00;
  border-bottom: 1px solid #A0A0A0;

}


.bordoSingolo {
  border: 1px solid;
  border-radius: 5px;
  color: #232222;
}

.bordoSingoloWarning {
  border: 1px solid;
  border-radius: 5px;
  color: #a89d04;
}

.bordoSingoloSuccess {
  border: 1px solid;
  border-radius: 5px;
  color: #0c7b02;
}

.dettModal {
  align-items: center;
  justify-content: center;
  --min-height: 85%;
  --min-width: 90%;
  --max-height: 90%;
  --max-width: 95%;
  scrollbar-width: none;
}